import React, {useState, useEffect, useRef, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useSmartNavigate from 'hooks/useSmartNavigate'

import {Alert, Button, Col} from 'reactstrap'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import LicenseAndEventStatisticsView from './components/LicenseAndEventStatisticsView'
import withUriParams from 'hocs/withUriParams'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'

import {queryStudentGroups, queryLicenseEvent} from '../data/adminQueries'
import {updateEvent, deleteSolutions, deleteSimulationsAndResults} from '../data/adminMutations'

import {prepareEventEditObjectForSave, prepareEventObjectForEdit} from '../data/adminEventObjects'
import prototypeUtils from 'utils/prototypeUtils'
import entityUtils from 'utils/entityUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import adminPrototypes from '../data/adminStaticPrototypes'
import {ADMIN_EVENT_VIEW, DB_ROLE_NAME_SUPERADMIN} from 'constants/constants'
import {breadcrumbsStore} from 'appBase/TopNav'

// specially modified fields
const licenseCompetitionEventProto = [
    ...adminPrototypes.licenseCompetitionEvent,
    ...adminPrototypes.licenseCompetitionEventPart];
const licenseCourseEventProto = [
    ...adminPrototypes.licenseCourseEvent,
    ...adminPrototypes.licenseCourseEventPart];
const superlicenseCompetitionEventProto = [
    ...adminPrototypes.licenseCompetitionEvent,
    ...adminPrototypes.licenseEventSuperlicensePart];
const superlicenseCourseEventProto = [
    ...adminPrototypes.licenseCourseEvent,
    ...adminPrototypes.licenseEventSuperlicensePart];

const catalogEventProto = prototypeUtils.discardFieldsFromPrototype(
    adminPrototypes.licenseCourseEvent,
    ['name', 'studentGroupId', 'testerGroupId', 'isArchived', 'isPublished']);


const AdminEventView = compose(
    withUriParams([
        ['mode', String, {optional: true, values: ['edit']}]]),
    queryLicenseEvent,
    queryStudentGroups,
    updateEvent,
    deleteSolutions,
    deleteSimulationsAndResults,
    withGeneratorDataSource,
    withInitialDataLoadWaiting(['licenseEvent']) //, 'studentGroups'])
)(props => {

    const {mode, dataSource, setDataSource, licenseEvent,
        updateEvent, deleteSolutions, deleteSimulationsAndResults,
        // features:
        enableCatalogPrototypes, enableDeleteStudentSolutions, showBackLink} = props;

    const [error, setError] = useState(null);
    const [isDeleteSimulationsAndResultsMutationStarted, setIsDeleteSimulationsAndResultsMutationStarted] = useState(false);

    const {t} = useTranslation();

    // links

    const containerId = ADMIN_EVENT_VIEW;
    breadcrumbsStore.register(containerId);

    useEffect(() => {
        const breadcrumbs = [];
        breadcrumbs.push({
            name: licenseEvent.type === 'competition'
                ? t('appAdmin.views.AdminEventView.competition')
                : t('appAdmin.views.AdminEventView.course'),
            to: `${props.uri}`
        });
        const isTerminalBreadcrumbs = !props.uriParams;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminalBreadcrumbs);
    }, [licenseEvent, props.uri, props.uriParams, containerId, t]);

    // data source

    const licenseEventEditObject = useMemo(() => prepareEventObjectForEdit(licenseEvent), [licenseEvent]);

    useEffect(() => {
        if (!licenseEvent) {
            return;
        }
        setDataSource(
            licenseEventEditObject,
            (data) => {
                data = prepareEventEditObjectForSave(data, licenseEvent.isSuperlicense);
                updateEvent(entityUtils.filterFields(
                    data, [
                        'id',
                        'nameMl',
                        'descriptionMl',
                        'imageUrl',
                        'welcomeTitleMl',
                        'welcomeMessageMl',
                        'startsAt',
                        'endsAt',
                        'studentGroupId',
                        'testerGroupId',
                        'teamMembersLimit',
                        'isArchived',
                        'isPublished',
                        'isCompetitionMode',
                        'competitionRules',
                        'competitionModeInfoMessageMl',
                        'isUserTeams',
                        'visibilityType',
                        'isRegistrationAllowed',
                        'isVisibleWithoutRegistration',
                        'isTournamentTableVisible',
                        'isOntiMode',
                        'ontiParameters']))
                    .then(({data: {updateEvent: {error}}}) =>
                        setError(error ? parseBackendMessage(error, t): null));
            });
    }, [licenseEvent, licenseEventEditObject, dataSource, setDataSource, updateEvent, t]);

    const participantsUrlRef = useRef();
    const selfregistrationUrlRef = useRef();

    const navigate = useSmartNavigate();

    // rendering

    const {onChange, onValidate,
        saveAndReset, isChanged, hasErrors, disabled,
        editConfirmationMessage,
        executeWithConfirmation, executeWithLock} = props;

    const navigateWithLock = (to) => executeWithLock(() => navigate(to));

    const studentGroups =
        props.studentGroups &&
        props.studentGroups.map(t => ({localId: t.id, name: t.name}));


    const isUserSuperadmin =
        props.user && props.user.roles && props.user.roles.some(r => r.name === DB_ROLE_NAME_SUPERADMIN);

    const prototype =
        licenseEvent && (
            enableCatalogPrototypes ? catalogEventProto :
            licenseEvent.type === 'competition' ? (licenseEvent.isSuperlicense || isUserSuperadmin ? superlicenseCompetitionEventProto : licenseCompetitionEventProto) :
            licenseEvent.type === 'course' ? (licenseEvent.isSuperlicense || isUserSuperadmin ? superlicenseCourseEventProto : licenseCourseEventProto) : null);

    return (
        <div className="flex-grow d-flex flex-column scroll-parent">
            <SaveButtonsBlock withEditMode
                              isChanged={isChanged}
                              hasErrors={hasErrors}
                              onSave={mode === 'edit' && !disabled && (() => {
                                  const saveAndRedirect = () => {
                                      saveAndReset();
                                      navigate(`${props.uri}${props.location.search}`);
                                  };

                                  if (!licenseEvent.isArchived && dataSource.isArchived) {
                                      executeWithConfirmation(saveAndRedirect, t('appAdmin.views.AdminEventView.archivedConfirmation'));
                                  } else {
                                      saveAndRedirect();
                                  }
                              })}
                              onCancel={mode === 'edit' && !disabled && (() => {
                                  setDataSource(null);
                                  navigate(`${props.uri}${props.location.search}`);
                              })}
                              actions={!disabled && [
                                  mode !== 'edit' && licenseEvent && licenseEvent.hasScenarioSimOrConstellationOrProg && {
                                      disabled: isDeleteSimulationsAndResultsMutationStarted || !licenseEvent.hasSuccessfullyCommittedSimulations,
                                      name: t('appAdmin.views.AdminEventView.deleteSimulationsAndResults'),
                                      color: 'danger',
                                      allowOnErrors: true,
                                      action: () => {
                                          executeWithConfirmation(() => {
                                              setIsDeleteSimulationsAndResultsMutationStarted(true);
                                              let isDeleteRunning = false;
                                              deleteSimulationsAndResults(licenseEvent.id, null, isDeleteRunning)
                                                  .then(({data: {deleteSimulationsAndResults: {hasRunning, error}}}) => {
                                                      setIsDeleteSimulationsAndResultsMutationStarted(false);
                                                      if (!hasRunning) {
                                                          setError(error ? parseBackendMessage(error, t): null);
                                                          return;
                                                      }
                                                      // another confirmation
                                                      executeWithConfirmation(
                                                          () => {
                                                              setIsDeleteSimulationsAndResultsMutationStarted(true);
                                                              isDeleteRunning = true;
                                                              deleteSimulationsAndResults(licenseEvent.id, null, isDeleteRunning)
                                                                  .then(({data: {deleteSimulationsAndResults: {_, error}}}) => {
                                                                      setIsDeleteSimulationsAndResultsMutationStarted(false);
                                                                      setError(error ? parseBackendMessage(error, t): null);
                                                                 });
                                                          },
                                                          t('appAdmin.views.AdminEventView.deleteSimulationsAndResultsHasRunningConfirmation'));
                                                 });
                                          },
                                          t('appAdmin.views.AdminEventView.deleteSimulationsAndResultsConfirmation'));
                                  }},
                                  mode !== 'edit' && enableDeleteStudentSolutions && licenseEvent && licenseEvent.hasScenarioSimOrConstellationOrProgOrTest && {
                                      disabled: !licenseEvent.studentSolutionsNumber,
                                      name: `${t('appAdmin.views.LicenseScenarioView.deleteStudentSolutions')} (${licenseEvent.studentSolutionsNumber || 0})`,
                                      color: 'danger',
                                      allowOnErrors: true,
                                      action: () => {
                                          const isTest = false;
                                          executeWithConfirmation(() => deleteSolutions(licenseEvent.id, null, isTest)
                                                  .then(({data: {deleteSolutions: {error}}}) => setError(error ? parseBackendMessage(error, t): null)),
                                              t('appAdmin.views.LicenseScenarioView.deleteStudentSolutionsConfirmation')
                                                  .replace('{num}', licenseEvent.studentSolutionsNumber));
                                      }},
                                  mode !== 'edit' && {
                                      name: t('appAdmin.views.AdminEventView.edit'),
                                      allowOnErrors: true,
                                      action: () => {
                                          const doNavigate = () => navigateWithLock(`${props.uri}/edit${props.location.search}`);
                                          editConfirmationMessage ? executeWithConfirmation(doNavigate, editConfirmationMessage) : doNavigate();
                                      }}]}
                              executeWithLock={executeWithLock}
                              withBack={showBackLink} />

            {error &&
            <Alert className="mb-0" color="danger">{error}</Alert>}

            {licenseEvent &&
            <>
            <LicenseAndEventStatisticsView licenseId={licenseEvent.licenseId} eventId={licenseEvent.id} errors={props.errors} loading={props.loading} withEvents withStudents withDataSize />
            <div className="flex-grow workpanel-gray scroll">
                {dataSource &&
                <GeneratorBlock data={dataSource}
                                items={prototype}
                                onChange={onChange} onValidate={onValidate}
                                collectionsDict={{studentGroups, licenseId: licenseEvent.licenseId, eventId: licenseEvent.id}}
                                disabled={mode !== 'edit'}
                                wide />}
                {licenseEvent.participantsUrl &&
                <div className="mt-5 form-group row g-0">
                    <Col sm={2}>
                        <span>{t('appAdmin.views.AdminEventView.studentsLink')}</span>
                    </Col>
                    <Col sm={10}>
                        <div className="g-0 d-flex flex-row">
                            <input className="form-control flex-shrink-grow" type="text" value={licenseEvent.participantsUrl} readOnly ref={participantsUrlRef} />
                            <Button className="ms-2" onClick={() =>{
                                participantsUrlRef.current.focus();
                                participantsUrlRef.current.select();
                                document.execCommand('copy');
                                window.getSelection().removeAllRanges();
                            }}>{t('appAdmin.views.AdminEventView.copy')}</Button>
                        </div>
                    </Col>
                </div>}
                {dataSource && dataSource.visibilityType === 'hidden' &&
                <div className="d-flex flex-row form-group g-0">
                    <Col sm={2}>
                        <span>{t('appAdmin.views.AdminEventView.selfRegistrationLink')}</span>
                    </Col>
                    <Col sm={10}>
                        {!licenseEvent.selfregistrationUrl &&
                        <span>{t('appAdmin.views.AdminEventView.willBeAvaiableAfterSaving')}</span>}
                        {licenseEvent.selfregistrationUrl &&
                        <div className="g-0 d-flex flex-row">
                            <input className="form-control flex-shrink-grow" type="text" value={licenseEvent.selfregistrationUrl} readOnly ref={selfregistrationUrlRef} />
                            <Button className="ms-2" onClick={() =>{
                                selfregistrationUrlRef.current.focus();
                                selfregistrationUrlRef.current.select();
                                document.execCommand('copy');
                                window.getSelection().removeAllRanges();
                            }}>{t('appAdmin.views.AdminEventView.copy')}</Button>
                        </div>}
                    </Col>
                </div>}
            </div>
            </>}
        </div>);
});

export default AdminEventView;